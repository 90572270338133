import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../config.json";
import "./WorkReqForm.css";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-toastify";
import {constant} from "../../utils/constant";
import {inputOnInvalid} from "../../utils/helpers";

const WorkReqForm = () =>{
    const [formDes, setFormDes] = useState([]);
    const [token , setToken] = useState("");
    const [formInfo,setFormInfo] = useState({
        name: "",
        dateOfBirth: "",
        landLine : "",
        mobileNumber :"",
        education: "",
        jobPosition: "" ,
        email:"",
        address: "",
        workExperience: "",
        onlineCVAddress: ""
    });
    useEffect(() => {
        getFormDes()
        document.title = "فرم درخواست همکاری تفکر درخشان پایا";
    }, []);
    function onChangeRecaptcha(value) {
        setToken(value);
    }
    const getFormDes = () =>{
        axios.get((config.Address) + `api/Context/10`)
            .then((response) => {
                setFormDes(response.data);
                //console.log("form description :" , response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    useEffect(() => {
        successMessageRef.current?.scrollIntoView({block:"center"})
    }, [showSuccessMessage]);
    const successMessageRef = useRef();

    const submitForm = (event) => {
        event.preventDefault()
        const data = {
            Name : `${formInfo.name}`,
            DateOfBirth : `${formInfo.dateOfBirth}`,
            LandLine : `${formInfo.landLine}`,
            MobileNumber : `${formInfo.mobileNumber}`,
            Education : `${formInfo.education}`,
            JobPosition : `${formInfo.jobPosition}`,
            Email : `${formInfo.email}`,
            Address : `${formInfo.address}`,
            WorkExperience : `${formInfo.workExperience}`,
            OnlineCVAddress : `${formInfo.onlineCVAddress}`,
            token :`${token}`
        }
        axios.post((config.Address) + "api/CooperationRequest" , data)
            .then((response) => {
                //console.log("message send post request response:" , response);
                setShowSuccessMessage(true);
                setFormInfo({
                    name: "",
                    dateOfBirth: "",
                    landLine : "",
                    mobileNumber :"",
                    education: "",
                    jobPosition: "" ,
                    email:"",
                    address: "",
                    workExperience: "",
                    onlineCVAddress: ""
                })
            })
            .catch((error) => {
                toast(error.response.data);
                console.log(error);
            });
    }

    return(
        <>
            {/*Top header section*/}
            <section>
                <div className="row top-div">
                    <div className="m-auto appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300">
                        <h3 className="mt-sm-3 mt-lg-5 w-100">{formDes.ctitle}</h3>
                    </div>
                </div>
                <div style={{overflow: "hidden"}} >
                    <svg viewBox="0 0 500 90" preserveAspectRatio="none" style={{height: "100%" , width: "100%"}}>
                        <path d="M-4.85,14.26 C144.13,154.03 272.23,-20.17 505.30,37.89 L504.73,-5.41 L-8.80,-6.40 Z" style={{stroke: "none", fill: "rgb(239, 241, 243)"}}></path>
                        <path d="M-2.59,23.13 C124.38,166.82 271.10,-20.17 500.22,47.72 L504.73,-5.41 L-8.80,-6.40 Z" style={{stroke: "none", fill: "rgba(239, 241, 243 , 0.4)"}}></path>
                    </svg>
                </div>
            </section>
            <div className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">
                <section id="body-content">
                    <div dangerouslySetInnerHTML={{__html:formDes.abstract}}/>
                    <div dangerouslySetInnerHTML={{__html:formDes.context1?.replaceAll("src=\"/file","src="+(config.ImageReplaceAddress)+"/file")}} />
                </section>
            </div>
            <div className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">
                <section className="form-style">
                    { !showSuccessMessage &&
                        <section id="form-section">
                        <div className="container mt-3">
                            <h2>فرم درخواست همکاری</h2>
                            <form onSubmit={submitForm}>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="name">نام و نام خانوادگی * </label>
                                        <input type="text" className="form-control" id="name" required value={formInfo.name}
                                               onChange={(event)=>setFormInfo({...formInfo,name:event.target.value})}
                                               //onInvalid = {inputOnInvalid}
                                        />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="dateOfBirth">تاریخ تولد (روز/ماه/سال) * </label>
                                        <input type="text" className="form-control" id="dateOfBirth" required value={formInfo.dateOfBirth}
                                               onChange={(event)=>setFormInfo({...formInfo,dateOfBirth:event.target.value})}
                                               //onInvalid = {inputOnInvalid}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="landLine">شماره تماس ثابت</label>
                                        <input type="text" className="form-control" id="landLine" value={formInfo.landLine}
                                               onChange={(event)=>setFormInfo({...formInfo,landLine:event.target.value})}/>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="mobileNumber">شماره تماس همراه *</label>
                                        <input type="text" className="form-control" id="mobileNumber" required value={formInfo.mobileNumber}
                                               onChange={(event)=>setFormInfo({...formInfo,mobileNumber:event.target.value})}
                                               //onInvalid = {inputOnInvalid}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="education">تحصیلات *</label>
                                        <select className="form-select" aria-label="Default select" id="education" required value={formInfo.education}
                                                onChange={(event)=>setFormInfo({...formInfo,education:event.target.value})}
                                                // onInvalid = {inputOnInvalid}
                                        >
                                            <option value="">میزان تحصیلات خود را انتخاب کنید</option>
                                            {constant.EDUCATIONOPTIONS.map(edu =>
                                                <option key={edu} value={edu}>{edu}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="jobPosition"> موقعیت شغلی درخواستی *</label>
                                        <select className="form-select" aria-label="Default select" id="jobPosition" required value={formInfo.jobPosition}
                                                onChange={(event)=>setFormInfo({...formInfo,jobPosition:event.target.value})}
                                                // onInvalid = {inputOnInvalid}
                                        >
                                            <option value="">موقعیت شغلی درخواستی خود را انتخاب کنید</option>
                                            {constant.JOBPOSITIONOPTIONS.map(position =>
                                                <option key={position} value={position}>{position}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="email">آدرس پست الکترونیک *</label>
                                        <input type="email" className="form-control" id="email" required style={{textAlign:"right"}} value={formInfo.email}
                                               onChange={(event)=>setFormInfo({...formInfo,email:event.target.value})}
                                               //onInvalid = {inputOnInvalid}
                                        />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="homeAddress">آدرس محل سکونت *</label>
                                        <input type="text" className="form-control" id="homeAddress" required value={formInfo.address}
                                               onChange={(event)=>setFormInfo({...formInfo,address:event.target.value})}
                                               //onInvalid = {inputOnInvalid}
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <label htmlFor="experience">سابقه کار *</label>
                                    <input type="text" id="experience" className="form-control" required value={formInfo.workExperience}
                                           onChange={(event)=>setFormInfo({...formInfo,workExperience:event.target.value})}
                                           //onInvalid = {inputOnInvalid}
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="CVaddress">آدرس رزومه آنلاین</label>
                                    <input type="text" id="CVaddress" className="row form-control m-auto" value={formInfo.onlineCVAddress}
                                           onChange={(event)=>setFormInfo({...formInfo,onlineCVAddress:event.target.value})}/>
                                </div>
                                <div className="row">
                                    <div className="text-center my-4">
                                        <ReCAPTCHA sitekey="6LeOaAspAAAAAF479ZdYprjZrz9tv2xoUh-lpaLp" onChange={onChangeRecaptcha} id="g-reCaptcha"/>
                                    </div>
                                    <button type="submit" className="btn btn-gradient text-3 font-weight-bold px-4 py-2 mx-auto w-auto my-3">ارسال فرم</button>
                                </div>
                            </form>
                        </div>
                    </section>}
                    { showSuccessMessage &&
                        <section ref={successMessageRef}>
                        <div style={{textAlign:"center"}} className="fs-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#2ECC71" className="bi bi-check-circle mx-2" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                            </svg>
                            متشكريم؛ارسال فرم با موفقيت انجام شد.
                            <br/>
                            <button type="button" className="btn btn-gradient fs-6 px-4 py-2 mx-auto w-auto my-3" onClick={() => setShowSuccessMessage(false)}>نمایش فرم جدید</button>
                        </div>
                    </section>
                    }
                </section>
            </div>
            {/*shape divider near footer*/}
            <div className="shape-divider shape-divider-bottom shape-divider-reverseY z-index-0">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2000 260" preserveAspectRatio="xMinYMin" style={{backgroundColor:"#fff"}}>
                    <path fill="rgb(239, 241, 243)" d="M-42,42c21.03-0.71,49.03-1.3,82-1c10.06,0.09,73,0.78,147,7c95.4,8.01,191.25,23.7,278,40
                                   c188.62,35.43,247.14,57.2,353,79c102.08,21.02,252.74,44.36,445,46c0,22.68,0,45.35,0,68.03c-433.33,0-866.67,0-1300,0
                                   C-38.67,201.35-40.33,121.68-42,42z"/>
                    <path fill="#222529" d="M-39,233c18.45-5.57,45.76-13.68,79-23c65.89-18.48,100.28-28.12,140-36c64.67-12.83,115.09-15.35,151-17
                                   c72.37-3.33,126.81,0.97,200,7c21.78,1.79,35.05,3.15,147,16c185.79,21.33,205.66,24.08,252,27c85.5,5.38,150.46,4.35,168,4
                                   c48.7-0.96,73.47-3.33,246-19c174.43-15.84,185.89-16.75,211-18c76.46-3.81,133.48-3.48,217-3c83.26,0.48,125.58,2.59,166,6
                                   c37.33,3.15,68.3,7,90,10c0,28.67,0,57.35,0,86.02c-688.33,0-1376.67,0-2065,0C-37.67,259.68-38.33,246.34-39,233z"/>
                </svg>
            </div>
        </>
    )
}
export default WorkReqForm;
