import React, {useState} from "react";
import axios from "axios";
import config from "../../../config.json";
import { ToastContainer, toast } from 'react-toastify';
import './sendMessage.css';
import ReCAPTCHA from "react-google-recaptcha";
import {inputOnInvalid} from "../../../utils/helpers";

const SendMessage = () =>{
    const[sendMessageInfo , setSendMessageInfo] = useState({
        name : "",
        emailAddress : "",
        messageBody : ""
    });
    const [token , setToken] = useState("");
    function onChangeRecaptcha(value) {
        //console.log("Captcha value:", value)
        setToken(value);
    }
    const sendMessage = (event) => {
        event.preventDefault()
        const data = {
            SenderName : `${sendMessageInfo.name}`,
            SenderEmail : `${sendMessageInfo.emailAddress}`,
            Message : `${sendMessageInfo.messageBody}`,
            token :`${token}`
        }
        axios.post((config.Address) + "api/Contact" , data)
            .then((response) => {
                console.log("message send post request response:" , response);
                toast(response.data);
                setSendMessageInfo({
                    name : "",
                    emailAddress : "",
                    messageBody : ""
                })
            })
            .catch((error) => {
                toast(error.response.data);
                console.log(error);
            });
    }

    return(
        <div className="col-lg-6 ps-lg-5">
            <span className="d-block text-color-grey font-weight-normal custom-font-size-2 text-color-light opacity-6 text-center mb-2">فـرم تمــاس</span>
            <h2 className="font-weight-bold text-10 text-center pb-3 mb-4">پیام خود را ارسال کنید</h2>
            <div className="row">
                <div className="col">
                    <form className=" custom-form-style-1 form-errors-light" onSubmit={sendMessage} id="sendMessageForm">
                        <div className="row">
                            <div className="form-group col">
                                <input type="text" value={sendMessageInfo.name} maxLength="100" className="form-control"
                                       onChange={(event)=>setSendMessageInfo({...sendMessageInfo,name:event.target.value})} placeholder="نام و نام خانوادگی شما" required
                                       //onInvalid = {inputOnInvalid}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col">
                                <input type="email" style={{direction:"rtl"}} value={sendMessageInfo.emailAddress}
                                       data-msg-email="لطفا آدرس ایمیل معتبر وارد نمایید." maxLength="100" className="form-control"
                                       onChange={(event)=>setSendMessageInfo({...sendMessageInfo,emailAddress:event.target.value})} placeholder="آدرس ایمیل" required
                                       //onInvalid = {inputOnInvalid}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col">
                                <textarea maxLength="5000" value={sendMessageInfo.messageBody} rows="6" className="form-control"
                                          onChange={(event)=>setSendMessageInfo({...sendMessageInfo,messageBody:event.target.value})} placeholder="پیام شما" required
                                          //onInvalid = {inputOnInvalid}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col text-center">
                                <div className="text-center">
                                    <ReCAPTCHA sitekey="6LeOaAspAAAAAF479ZdYprjZrz9tv2xoUh-lpaLp" onChange={onChangeRecaptcha} id="g-reCaptcha"/>
                                </div>
                                <input type="submit" value="ارسال پیام" className="btn btn-gradient text-3 font-weight-bold px-5 py-3" data-loading-text="در حال ارسال ..." />
                            </div>
                        </div>
                        <ToastContainer
                            position="top-center"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={true}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}
export default SendMessage;

