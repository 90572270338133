import React, {useEffect, useState} from 'react';
import logo from '../../img/demos/startup-agency/tafakorLogo.png'
import axios from "axios";
import config from "../../config.json";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Navbar.css';
import {Link} from "react-router-dom";

const MainNavbar = () =>{
    const [menuItems , setMenuItems] = useState([]);
    useEffect(() => {
        getMenuItems()
    }, []);
    const getMenuItems = () =>{
        axios.get((config.Address) + "api/GetMenuView")
            .then((response) => {
                setMenuItems(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return(
        <header id="header" className="header-effect-shrink header-transparent" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyChangeLogo': true, 'stickyStartAt': 30, 'stickyHeaderContainerHeight': 70}">
            <div className="header-body header-body-bottom-border border-top-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="900">
                <Navbar expand="lg" className="bg-body-tertiary pt-3" id="navbar">
                    <Container style={{zIndex: '90'}}>
                        <Navbar.Brand href="/">
                            <img src={logo} className="img-fluid" width="123" height="32" alt="tafakor-logo" />
                        </Navbar.Brand>
                        <div className="header-column justify-content-end">
                            <div className="header-row">
                                <div className="header-nav header-nav-bottom-line-effect-1">
                                    <div className="header-nav-main-text-capitalize header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-effect-2 header-nav-main-sub-effect-1">
                                        <div className="d-none d-lg-block">
                                            <Navbar.Collapse id="basic-navbar-nav">
                                                <Nav className="mr-auto">
                                                    {menuItems.map(menu =>
                                                        <Nav.Link as={menu.menuLinkUrl?.includes('#') ? undefined : Link} key={menu.menuId} to={"/" + menu.menuLinkUrl} href={"/" + menu.menuLinkUrl} target={menu.targetTypeName}
                                                                  className="navLink-style nav-link text-center text-decoration-none mx-2 text-color-hover-primary hover-underline-animation"
                                                        >
                                                            {menu.menuName}
                                                        </Nav.Link>
                                                    )}
                                                </Nav>
                                            </Navbar.Collapse>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="tel:01732320790" className="font-weight-semibold text-color-dark text-color-hover-primary text-decoration-none custom-font-size-2 ps-1 ms-lg-3 ms-xl-4">32320790 (017)</a>
                        <Navbar.Toggle aria-controls="navbarNavDropdown" style={{border:"0"}} className="navbar-toggler">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#172a56" className="bi bi-list" viewBox="0 0 16 16" style={{marginTop:"-4px" , marginLeft:"-8px"}}>
                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                            </svg>
                        </Navbar.Toggle>
                    </Container>
                    <div style={{display: 'contents'}} className={"d-lg-none"}>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto bg-white shadow-sm m-auto p-2 rounded-3" style={{width:"85%"}}>
                                {menuItems.map(menu =>
                                    <Nav.Link key={menu.menuId} href={"/" + menu.menuLinkUrl} target={menu.targetTypeName}
                                              className="navLink-style nav-link text-center text-decoration-none mx-2 text-color-hover-primary"
                                    >
                                        {menu.menuName}
                                    </Nav.Link>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </div>
        </header>
    )
}
export default MainNavbar;