import React from "react";
import ContactUs from "./contactUs/contactUs";
import SendMessage from "./sendMessage/sendMessage";
const Footer = () =>{
    return(
        <>
            <footer id="footer" className="border-0 bg-dark pt-4 mt-0" data-spy-offset="15">
                <div className="custom-svg-wrapper-4 pb-5">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1667 581" data-plugin-float-element-svg="true">
                        <g data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.3, 'isInsideSVG': true, 'transition': true, 'transitionDuration': 2000}">
                            <circle className="custom-svg-fill-color-secondary" fill="#4F4BFC" cx="39" cy="315" r="17"/>
                            <circle fill="#222529" cx="38.69" cy="315.31" r="15.43"/>
                        </g>
                        <g data-plugin-float-element data-plugin-options="{'startPos': 'bottom', 'speed': 0.2, 'isInsideSVG': true, 'transition': true, 'transitionDuration': 2000}">
                            <circle className="custom-svg-fill-color-secondary" fill="#5349FF" cx="257" cy="545" r="4"/>
                        </g>
                        <g data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.3, 'isInsideSVG': true, 'transition': true, 'transitionDuration': 2000}">
                            <circle fill="#333539" cx="501.5" cy="304.5" r="10.5"/>
                        </g>
                        <g data-plugin-float-element data-plugin-options="{'startPos': 'bottom', 'speed': 0.2, 'isInsideSVG': true, 'transition': true, 'transitionDuration': 2000}">
                            <circle className="custom-svg-fill-color-secondary" fill="#5349FF" cx="738" cy="36" r="6"/>
                        </g>
                        <g data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.3, 'isInsideSVG': true, 'transition': true, 'transitionDuration': 2000}">
                            <circle className="custom-svg-fill-color-secondary" fill="#5349FF" cx="1630" cy="368" r="6"/>
                        </g>
                    </svg>
                    <div className="container mt-5 mt-lg-0">
                        <div className="row">
                            <ContactUs />
                            <SendMessage/>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright bg-dark mt-5 pb-5">
                    <div className="container footer-top-light-border pt-4">
                        <div className="row">
                            <div className="col">
                                <p dir="ltr" style={{fontFamily:"vazir"}} className="text-center text-color-light opacity-5">© 2024 tafakor.net. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;