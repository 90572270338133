import React from "react";
import Home from "../../components/homePage/home";
import AboutUs from "../../components/aboutUs/aboutUs";
import TeamMembers from "../../components/teamMembers/teamMembers";
import OurWorks from "../../components/ourWorks/ourWorks";
import WorkOffers from "../../components/workOffers/workOffers";
import LastBlogs from "../../components/lastBlogs/lastBlogs";
const LandingPage = () =>{
    return(
        <>
            <div role="main" className="main">
                <Home />
                <AboutUs />
                <TeamMembers />
                <OurWorks />
                <WorkOffers />
                <LastBlogs />
            </div>
        </>
    )
}
export default LandingPage;