import React from "react";
import LocationIcon from '../../../img/demos/startup-agency/icons/icon-location.svg'
import MailIcon from '../../../img/demos/startup-agency/icons/icon-mail.svg'
import PhoneIcon from '../../../img/demos/startup-agency/icons/icon-phone.svg'
import CalenderIcon from '../../../img/demos/startup-agency/icons/icon-calendar.svg'

const ContactUs = () =>{
    return(
        <div className="col-lg-6 text-center pb-3 pb-lg-0 mb-5 mb-lg-0">
            <span className="d-block text-color-grey font-weight-normal custom-font-size-2 text-color-light opacity-6 mb-2">راه هـای ارتـباطـی</span>
            <h2 className="font-weight-bold text-color-light text-10 mb-5">ارتباط با ما</h2>
            {/*<ul className="header-social-icons social-icons social-icons-clean social-icons-medium ms-0 ms-xl-3 d-none d-md-inline-flex">*/}
            {/*    <li className="social-icons-facebook"><a href="https://www.facebook.com/" target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>*/}
            {/*    <li className="social-icons-twitter"><a href="https://www.twitter.com/" target="_blank" title="Twitter"><i className="fab fa-twitter"></i></a></li>*/}
            {/*    <li className="social-icons-linkedin"><a href="https://www.linkedin.com/" target="_blank" title="Linkedin"><i className="fab fa-linkedin-in"></i></a></li>*/}
            {/*</ul>*/}
            <div className="row">
                <div className="col-lg-6">
                    <div className="row pb-5 mb-5">
                        <div className="col">
                            <div data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'isInsideSVG': true, 'transition': true, 'transitionDuration': 2000}">
                                <img className="icon-animated" width="55" src={LocationIcon} alt="LocationIcon" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary mb-3'}" />
                                <h4 className="font-weight-bold text-color-light text-5 mb-0">آدرس</h4>
                                <p className="text-4 text-color-light opacity-5 font-weight-light mb-0">گرگان، خیابان ولیعصر، عدالت 23، مجتمع باران، واحد 8</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div data-plugin-float-element data-plugin-options="{'startPos': 'bottom', 'speed': 0.1, 'isInsideSVG': true, 'transition': true, 'transitionDuration': 2000}">
                                <img className="icon-animated" width="55" src={MailIcon} alt="MailIcon" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-secondary mb-3'}" />
                                <h4 className="font-weight-bold text-color-light text-5 mb-0">ایمیل</h4>
                                <a href="https://mail.google.com/" className="text-4 text-color-light text-color-hover-primary opacity-5 font-weight-light mb-0">
                                    <span className="__cf_email__" >info@tafakor.net</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 pt-5">
                    <div className="row pb-5 mb-5">
                        <div className="col">
                            <div data-plugin-float-element data-plugin-options="{'startPos': 'bottom', 'speed': 0.2, 'isInsideSVG': true, 'transition': true, 'transitionDuration': 2000}">
                                <img className="icon-animated" width="55" src={PhoneIcon} alt="PhoneIcon" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-secondary mb-3'}" />
                                <h4 className="font-weight-bold text-color-light text-5 mb-0">تلفن تماس</h4>
                                <a href="tel:+1234567890" className="text-4 text-color-light text-color-hover-primary opacity-5 font-weight-light mb-0">32320790 (017)</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div data-plugin-float-element data-plugin-options="{'startPos': 'bottom', 'speed': 0.1, 'isInsideSVG': true, 'transition': true, 'transitionDuration': 2000}">
                                <img className="icon-animated" width="55" src={CalenderIcon} alt="CalenderIcon" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary mb-3'}" />
                                <h4 className="font-weight-bold text-color-light text-5 mb-0">روزها و ساعات کاری</h4>
                                <p className="text-4 text-color-light opacity-5 font-weight-light mb-0">شنبه - پنجشنبه / 8:00 صبح - 4:00 عصر</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactUs;