import React, {useEffect} from 'react';
import './App.css';
import Footer from "./components/footer/footer";
import {
    BrowserRouter as Router,
    Routes,
    Route, useLocation
} from 'react-router-dom';
import LandingPage from "./pages/landingPage/landingPage";
import 'react-toastify/dist/ReactToastify.css';
import ContextPage from "./pages/context/contextPage";
import WorkReqForm from "./pages/workRequestForm/WorkReqForm";
import MainNavbar from "./components/header/Navbar";
import Blogs from "./pages/blogsList/blogs";

const scripts = [
    //Head Libs
    "/assets/vendor/modernizr/modernizr.min.js",

    //Vendor
    "/assets/js/email-decode.min.js",
    "/assets/vendor/jquery/jquery.min.js",
    "/assets/vendor/jquery.appear/jquery.appear.min.js",
    "/assets/vendor/jquery.easing/jquery.easing.min.js",
    "/assets/vendor/jquery.cookie/jquery.cookie.min.js",
    "/assets/vendor/bootstrap/js/bootstrap.bundle.min.js",
    "/assets/vendor/jquery.validation/jquery.validate.min.js",
    //"/assets/vendor/jquery.easy-pie-chart/jquery.easypiechart.min.js",
    //"/assets/vendor/lazysizes/lazysizes.min.js",
    //"/assets/vendor/jquery.gmap/jquery.gmap.min.js",
    "/assets/vendor/owl.carousel/owl.carousel.min.js",
    //"/assets/vendor/isotope/jquery.isotope.min.js",
    "/assets/vendor/magnific-popup/jquery.magnific-popup.min.js",
    "/assets/vendor/vide/jquery.vide.min.js",
    //"/assets/vendor/vivus/vivus.min.js",
    "/assets/vendor/kute/kute.min.js",

    //Theme Base, Components and Settings
    "/assets/js/theme.js",

    //Current Page Vendor and Views
    "/assets/js/views/view.contact.js",

    //Demo
    "/assets/js/demos/demo-startup-agency.js",

    //Theme Initialization Files
    "/assets/js/theme.init.js",
]

function App() {
    useEffect(() => {
        window.navigation.addEventListener('navigate', () => {
            ["/assets/js/theme.init.js"].forEach(src => {
                const script = document.createElement('script');
                script.async = false;
                script.src = src;
                document.body.appendChild(script);
            })
        });
        scripts.forEach(src => {
            const script = document.createElement('script');
            script.async = false;
            script.src = src;
            document.body.appendChild(script);
        })
    }, []);
  return (
    <div className="App">
        <Router>
            <MainNavbar />
            <Routes>
                <Route path="/" exact element={<LandingPage />} />
                {/*<Route path="/about" exact element={<AboutUsPage />} />*/}
                <Route path="/context/:id" exact element={<ContextPage /> } />
                <Route path="/employment" exact element={<WorkReqForm /> } />
                <Route path="/blogs" exact element={<Blogs />} />
            </Routes>
        </Router>
        <Footer/>
    </div>
  );
}
export default App;
