import React, {useEffect, useState} from "react";
import './contextPage.css';
import axios from "axios";
import config from "../../config.json";
import {useParams} from "react-router-dom";

const ContextPage = () => {

    const params = useParams();
    const [context , setContext] = useState([]);
    useEffect(() => {
        getContext()
    }, []);
    useEffect(() => {
        document.title = context.ctitle;
    }, [context]);
    const getContext = () =>{
        axios.get((config.Address) + `api/Context/${params.id}`)
            .then((response) => {
                setContext(response.data);
                //console.log("The context:" , response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return(
        <>
            <section>
                <div className="row top-div">
                    {!context.smallImage ?
                        <div className="col-12 m-auto appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300">
                            <h3>{context.ctitle}</h3>
                        </div> :
                        <>
                            <div className="col-lg-6 col-md-6 m-auto appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300">
                                <h3>{context.ctitle}</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 m-auto appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500">
                                <img src={context.smallImage.replace("~/" , (config.ImageReplaceAddress))} className="img-fluid" alt={context.ctitle} />
                            </div>
                        </>
                    }
                </div>
                <div style={{overflow: "hidden"}} >
                    <svg viewBox="0 0 500 90" preserveAspectRatio="none" style={{height: "100%" , width: "100%"}}>
                        <path d="M-4.85,14.26 C144.13,154.03 272.23,-20.17 505.30,37.89 L504.73,-5.41 L-8.80,-6.40 Z" style={{stroke: "none", fill: "rgb(239, 241, 243)"}}></path>
                        <path d="M-2.59,23.13 C124.38,166.82 271.10,-20.17 500.22,47.72 L504.73,-5.41 L-8.80,-6.40 Z" style={{stroke: "none", fill: "rgba(239, 241, 243 , 0.4)"}}></path>
                    </svg>
                </div>
            </section>
            <section id="body-content">
                <div className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">
                    <div dangerouslySetInnerHTML={{__html:context.abstract}}/>
                    <div className="main-context" dangerouslySetInnerHTML={{__html:context.context1?.replaceAll("src=\"/file","src="+(config.ImageReplaceAddress)+"/file")}}></div>
                </div>
            </section>
            {/*shape divider near footer*/}
            <div className="shape-divider shape-divider-bottom shape-divider-reverseY z-index-0">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2000 260" preserveAspectRatio="xMinYMin" style={{backgroundColor:"#fff"}}>
                    <path fill="rgb(239, 241, 243)" d="M-42,42c21.03-0.71,49.03-1.3,82-1c10.06,0.09,73,0.78,147,7c95.4,8.01,191.25,23.7,278,40
                                   c188.62,35.43,247.14,57.2,353,79c102.08,21.02,252.74,44.36,445,46c0,22.68,0,45.35,0,68.03c-433.33,0-866.67,0-1300,0
                                   C-38.67,201.35-40.33,121.68-42,42z"/>
                    <path fill="#222529" d="M-39,233c18.45-5.57,45.76-13.68,79-23c65.89-18.48,100.28-28.12,140-36c64.67-12.83,115.09-15.35,151-17
                                   c72.37-3.33,126.81,0.97,200,7c21.78,1.79,35.05,3.15,147,16c185.79,21.33,205.66,24.08,252,27c85.5,5.38,150.46,4.35,168,4
                                   c48.7-0.96,73.47-3.33,246-19c174.43-15.84,185.89-16.75,211-18c76.46-3.81,133.48-3.48,217-3c83.26,0.48,125.58,2.59,166,6
                                   c37.33,3.15,68.3,7,90,10c0,28.67,0,57.35,0,86.02c-688.33,0-1376.67,0-2065,0C-37.67,259.68-38.33,246.34-39,233z"/>
                </svg>
            </div>
        </>
    )
}
export default ContextPage;