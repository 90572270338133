import React, {useEffect, useState} from "react";
import axios from "axios";
import config from "../../config.json";
const OurWorks = () =>{

    const[ourWorks , setOurWorks] = useState([]);
    useEffect(() => {
        getOurWorksLinks();
    }, []);
    const getOurWorksLinks = () =>{
        axios.get((config.Address) + "api/LinkTypes/5178/Links")
            .then((response) => {
                setOurWorks(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    //console.log("our works links:" , ourWorks);

    return(
        <section id="works" className="section section-with-shape-divider bg-secondary border-0 m-0">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 text-center">
                        <span className="d-block text-color-grey font-weight-normal custom-font-size-2 text-color-light opacity-6 mb-2">پــروژه هـا</span>
                        <h2 className="font-weight-bold text-color-light text-10 mb-4">کارها را با علاقه انجام میدهیم</h2>
                        <p className="custom-font-size-2 font-weight-light text-color-light mb-4 pb-3">معتقدیم پروژه هایی که با اشتیاق و تمرکز انجام می شوند، برای همه رضایت بخش است.</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-0 pb-5 mb-5">
                <div className="row">
                    <div className="col">
                        <div className="owl-carousel custom-carousel-backward-shape custom-dots-style-1 custom-dots-style-1-light" data-plugin-options="{'rtl':true, 'responsive': {'0': {'items': 1, 'stagePadding': 100}, '479': {'items': 1, 'stagePadding': 100}, '768': {'items': 3, 'stagePadding': 100}, '992': {'items': 3, 'stagePadding': 100}, '1200': {'items': 4}}, 'autoplay': true, 'autoplayTimeout': 5000, 'autoplayHoverPause': true, 'dots': true, 'nav': false, 'center': true, 'loop': true, 'margin': 25}">
                            {ourWorks.map(project =>
                                <div className="my-5" key={project.linkId}>
                                    <div /*href={project.linkAddress}*/>
                                        <div className="thumb-info thumb-info-hide-wrapper-bg custom-thumb-info-hover-1 box-shadow-4 rounded-0 my-3">
                                            <div className="thumb-info-wrapper rounded-0">
                                                <img src={project.linkImageFile.replace("~/" , (config.ImageReplaceAddress))} className="img-fluid rounded-0" alt={project.linkText} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-divider shape-divider-bottom" style={{height: "109px"}}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2000 109" preserveAspectRatio="xMinYMin">
                    <path fill="#FFFFFF" d="M-29,0c16.91,7.69,40.41,17.74,69,28c24.89,8.93,114.79,39.95,247,52c89.7,8.18,157.69,3.87,215,0
								c81.39-5.5,81.96-11.49,233-29c82.16-9.52,140.49-16.18,220-20c40.62-1.95,100.38-4.7,178-2c45.11,1.57,62.48,3.8,212,18
								c122.78,11.66,184.77,17.53,214,19c80.47,4.06,142.71,3.13,219,2c68.63-1.02,116.48,0.48,182-6c32.74-3.24,59.69-7.11,78-10
								c0,24.39,0,48.78,0,73.17c-691.67,0-1383.33,0-2075,0C-34.33,83.45-31.67,41.72-29,0z"/>
                </svg>
            </div>
        </section>
    )
}
export default OurWorks;