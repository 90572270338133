import React, {useEffect, useState} from "react";
import axios from "axios";
import config from "../../config.json";
import {Link} from "react-router-dom";
const LastBlogs = () =>{
    const [blogs , setBlogs] = useState([]);
    useEffect(() => {
        getBlogs()
    }, []);

    const getBlogs = () =>{
        axios.get((config.Address) + "api/ContextType/4308/contexts")
            .then((response) => {
                console.log("blogs:" , response);
                setBlogs(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return(
        <section id="blog" className="section section-with-shape-divider custom-bg-color-1 border-0 m-0">
            <div className="shape-divider shape-divider-reverse-y" style={{height: "385px"}}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2000 385" preserveAspectRatio="xMinYMin">
                    <circle fill="#9DA3F6" cx="57" cy="181" r="161"/>
                    <circle className="custom-svg-fill-color-secondary" fill="#5349FF" opacity="0.3" cx="75.5" cy="158.5" r="169.5"/>
                    <path fill="#FFFFFF" opacity="0.5" d="M-35,226c17.66,1.2,43.63,2.44,75,2c83.48-1.18,144.86-13.49,188-22c224.54-44.3,165.12-27.27,310-59
								c133.16-29.16,128.14-31.45,223-52c206.26-44.68,317.22-68.72,460-68c97.61,0.49,73.58,11.86,293,25c164.22,9.83,250.54,7.83,279,7
								c102.76-2.99,187.96-12.01,248-20c0-24.69,0-49.37,0-74.06c-694.67,0-1389.33,0-2084,0C-40.33,51.96-37.67,138.98-35,226z"/>
                    <path fill="#FFFFFF" d="M-37,185c17.21,1.44,42.06,3.17,72,4c81.96,2.26,170.2-3.07,325-30c54.07-9.4,98.99-18.41,221-45
								c255.1-55.59,223.26-53.86,287-64c57.13-9.09,228.37-36.32,423-18c36.66,3.45,96.62,10.63,187,14c16.19,0.6,75.77,2.66,156,1
								c90.18-1.87,157.94-7.44,248-15c46.95-3.94,99.2-8.84,156-15c0-12.82,0-25.65,0-38.47c-692.67,0-1385.33,0-2078,0
								C-39,47.35-38,116.18-37,185z"/>
                </svg>
            </div>
            <div className="container position-relative z-index-3 pt-2 pt-md-5 pb-1 pb-md-5 mt-5 mb-5 mb-lg-0 mb-xl-5">
                <div className="row pb-2 mt-5 mb-5">
                    <div className="col text-center">
                        <span className="text-color-grey font-weight-medium custom-font-size-2 d-block appear-animation mb-2" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">بـلاگ مـا</span>
                        <h2 className="font-weight-bold text-10 mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">آخرین اخبار و مقالات</h2>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">
                        <div className="owl-carousel custom-dots-style-1" data-plugin-options="{'rtl' : true, 'responsive': {'0': {'items': 1}, '476': {'items': 2}, '768': {'items': 1}, '992': {'items': 3}, '1200': {'items': 3}}, 'autoplay': false, 'autoplayTimeout': 5000, 'autoplayHoverPause': true, 'dots': true, 'nav': false, 'loop': true, 'margin': 60}">
                            {blogs.map(blog =>
                                <div key={blog.contextId}>
                                    <article className="mb-5">
                                        <div style={{padding:"30px 30px 5px 30px"}}>
                                            <img src={blog.smallImage.replace("~/" , (config.ImageReplaceAddress))} className="mb-2" alt={blog.ctitle} style={{borderRadius:"7px"}}/>
                                        </div>
                                        <div className="d-flex justify-content-center line-height-1 text-1 pb-1 mb-2">
                                            <span className="text-color-default text-color-hover-primary text-decoration-none">{blog.createDateTime}</span>
                                            {/*<a href="#" className="text-color-default text-color-hover-primary text-decoration-none custom-divider-1">0 نظر</a>*/}
                                            <span className="text-color-default custom-divider-1">مدیر سایت</span>
                                        </div>
                                        <h4 className="font-weight-bold text-6 text-center" style={{lineHeight:"1.5"}}>
                                            <Link to={`/context/${blog.contextId}`} target="_blank" className="text-color-dark text-color-hover-primary text-decoration-none">
                                                {blog.ctitle}
                                            </Link>
                                        </h4>
                                        <div dangerouslySetInnerHTML={{__html:blog.abstract}}/>
                                        <Link to={`/context/${blog.contextId}`} target="_blank" className="d-block font-weight-semibold text-color-secondary text-color-hover-primary text-decoration-none text-center">ادامه مطلب +</Link>
                                    </article>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-divider shape-divider-bottom shape-divider-reverse-y z-index-0" style={{height: "260px"}}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2000 260" preserveAspectRatio="xMinYMin">
                    <path fill="#FFFFFF" d="M-42,42c21.03-0.71,49.03-1.3,82-1c10.06,0.09,73,0.78,147,7c95.4,8.01,191.25,23.7,278,40
								c188.62,35.43,247.14,57.2,353,79c102.08,21.02,252.74,44.36,445,46c0,22.68,0,45.35,0,68.03c-433.33,0-866.67,0-1300,0
								C-38.67,201.35-40.33,121.68-42,42z"/>
                    <path fill="#222529" d="M-39,233c18.45-5.57,45.76-13.68,79-23c65.89-18.48,100.28-28.12,140-36c64.67-12.83,115.09-15.35,151-17
								c72.37-3.33,126.81,0.97,200,7c21.78,1.79,35.05,3.15,147,16c185.79,21.33,205.66,24.08,252,27c85.5,5.38,150.46,4.35,168,4
								c48.7-0.96,73.47-3.33,246-19c174.43-15.84,185.89-16.75,211-18c76.46-3.81,133.48-3.48,217-3c83.26,0.48,125.58,2.59,166,6
								c37.33,3.15,68.3,7,90,10c0,28.67,0,57.35,0,86.02c-688.33,0-1376.67,0-2065,0C-37.67,259.68-38.33,246.34-39,233z"/>
                </svg>
            </div>
        </section>
    )
}
export default LastBlogs;