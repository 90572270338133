import React, {useEffect, useState} from "react";
import axios from "axios";
import config from "../../config.json";
import {Link} from "react-router-dom";
import './workOffers.css';

const WorkOffers = () => {
    // const [workOfferForm, setWorkOfferForm] = useState([]);
    // useEffect(() => {
    //     getWorkOfferForm()
    // }, []);
    // const getWorkOfferForm = () =>{
    //     axios.get((config.Address) + "api/ContextType/4307/contexts")
    //         .then((response) => {
    //             //console.log("The form:" , response);
    //             setWorkOfferForm(response.data[3]);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }

    return(
        <div id="workOffers" className="container mt-4 mb-5">
            <div className="row justify-content-center">
                <div className="col-lg-10 text-center workOffer-texts-div">
                    <span className="text-color-grey font-weight-medium custom-font-size-2 d-block appear-animation mb-2" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">همکـاری بـا مـا</span>
                    <h2 className="font-weight-bold text-10 mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">عضوی از تیم ما شوید</h2>
                    <p className="custom-font-size-2 font-weight-light appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">
                        شرکت تفکر درخشان پایا مجموعه ای از افراد متخصص،با انگیزه و خلاق را گرد هم آورده است و در تلاش است تا بهترین خدمات و محصولات را با بالاترین سطح کیفیت عرضه نماید.
                    </p>
                    <p className="custom-font-size-2 font-weight-light mb-4 pb-3 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">
                        اگر به کار تیمی علاقه مند هستید و خلاقیت و چالش ها را دوست دارید با ما همراه شوید.
                    </p>
                    <Link to={`/employment`} target="_blank"
                       className="btn btn-gradient text-3 font-weight-bold px-5 py-3 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1400" >
                        فرم درخواست همکاری
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default WorkOffers;